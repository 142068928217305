<template>
  <div class="main">
    <nav-header></nav-header>
    <el-main class="home">
      <el-row type="flex" justify="center">
        <el-col :span="3"></el-col>
        <el-col :span="18" class="home-top">
          <el-row type="flex" justify="start" class="home-top-title-row">
            <el-col class="home-top-title">智能项目申报</el-col>
          </el-row>
          <el-row type="flex" justify="start">
            <el-col class="home-top-slogan">为企业提供更好更便捷的服务</el-col>
          </el-row>
          <el-row type="flex" justify="start" class="home-top-keyword-row">
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-zhuanzhu.png" alt="">
              <span>专注</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-zhuanye.png" alt="">
              <span>专业</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-fuwu.png" alt="">
              <span>服务</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-pinpai.png" alt="">
              <span>品牌</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" class="home-top-button-row">
            <el-col class="home-top-button">
              <el-button round @click="openLeaveContact">马上了解</el-button>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="3"></el-col>
      </el-row>
      <el-row class="home-second">
        <el-row>
          <el-col :span="24" class="home-second-bg"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-second-title">
          <el-col :span="3"></el-col>
          <el-col :span="21" style="align-items: center; display: flex;">
            <span class="home-little-title">热门政策</span><img src="../assets/image/version1/home-second-icon.png"
                                                                alt="" class="home-little-icon">
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-second-content">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex;">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item v-for="(item, i) in hotPolicies" :key="i" :name="String(i)" :title="item.title">
                <template #title>
                  <div class="el-collapse-item-title">{{ item.title }}</div>
                </template>
                <p>{{ item.content }}</p>
                <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 15px;">
                  <span>发布时间：{{ item.publishTime }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;来源：{{ item.source }}</span>
                  <a :href="item.url" target="_blank" style="cursor:pointer;color: #006Db8;">查看详情</a>
                </div>
              </el-collapse-item>
              <div class="home-second-content-button">
                <el-button round >
                  <router-link style="color: #ffffff" to="/allZc">查看全部</router-link>
                </el-button>
              </div>
            </el-collapse>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-third">
        <el-row type="flex" justify="start" class="home-third-title">
          <el-col :span="3"></el-col>
          <el-col :span="21" style="align-items: center; display: flex;">
            <span class="home-little-title">服务流程</span><img src="../assets/image/version1/home-second-icon.png"
                                                                alt="" class="home-little-icon">
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-third-content">
          <el-col :span="3"></el-col>
          <el-col :span="6">
            <el-row type="flex" justify="start" v-for="(item, i) in serviceProcessLeft" :key="i"
                    class="home-third-content-row">
              <el-col :span="6" style="display: flex; justify-content: center; align-items: center;">
                <img class="home-third-content-no" :src="item.imageName" alt="">
              </el-col>
              <el-col :span="18" style="display: flex; justify-content: center; align-items: center;">
                <div>
                  <div class="home-third-content-title">{{ item.title }}</div>
                  <span class="home-third-content-keyword">{{ item.keyword }}</span>
                  <img class="home-third-content-arrow" src="../assets/image/version1/home-third-content-arrow.png"
                       alt="">
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <img class="home-third-content-m" src="../assets/image/version1/home-third-content-m.png" alt="">
          </el-col>
          <el-col :span="6">
            <el-row type="flex" justify="start" v-for="(item, i) in serviceProcessRight" :key="i"
                    class="home-third-content-row-right">
              <el-col :span="18" style="display: flex; justify-content: center; align-items: center;">
                <div style="text-align: right;">
                  <div class="home-third-content-title">{{ item.title }}</div>
                  <img class="home-third-content-arrow-right"
                       src="../assets/image/version1/home-third-content-arrow.png" alt="">
                  <span class="home-third-content-keyword">{{ item.keyword }}</span>
                </div>
              </el-col>
              <el-col :span="6" style="display: flex; justify-content: center; align-items: center;">
                <img class="home-third-content-no" :src="item.imageName" alt="">
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-fourth">
        <el-row type="flex" justify="start" class="home-fourth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">初创期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-fourth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="startUpPeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-fifth">
        <el-row type="flex" justify="start" class="home-fifth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">成长期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-fifth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="growthPeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-sixth">
        <el-row type="flex" justify="start" class="home-sixth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">成熟期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-sixth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="maturePeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>

    </el-main>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavHeader from './../components/navHeader'
import NavFooter from './../components/navFooter'

export default {
  name: "home",
  data() {
    return {
      activeName: '0',
      hotPolicies: [
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省科学技术厅 河南省财政厅 关于组织申报2024年度第二批河南省重点研发专项的通知",
          content: "各省辖市科技局、财政局，济源示范区管委会科技、财政管理部门，郑州航空港经济综合实验区，各县（市）科技、财政主管部门，国家高新区、郑州经济技术开发区管委会，省直有关部门，各有关单位：",
          publishTime: "2024-08-21 19:54",
          source: "科技项目统筹推进处",
          url: "https://wap.kjt.henan.gov.cn/2024/08-21/3053331.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于2024年中央引导地方科技发展资金储备库项目受理的公示",
          content: "根据《中央引导地方科技发展资金管理办法》（财教〔2023〕276号）以及《关于开展 2024年度中央引导地方科技发展资金项目储备库建设的通知》（豫科〔2024〕71号）有关要求，共受理中央引导地方科技发展资金项目申报材料175项，现予以公示，公示期5个工作日，自2024年8月5日至8月9日。公示期间若有异议，请以书面形式向省科技厅实名反映。",
          publishTime: "2024-08-05 10:58",
          source: "科技成果转化与区域创新处",
          url: "https://wap.kjt.henan.gov.cn/2024/08-05/3032253.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于开展2024年度河南省创新龙头企业遴选工作的通知",
          content: "各省辖市科技局，济源示范区、航空港区科技管理部门，县（市）科技管理部门，各国家高新区管委会，各有关单位：\n" +
              "\n" +
              "　　为深入贯彻落实党的二十大精神，强化企业科技创新主体地位，加快培育一批创新水平高、发展速度快、成长性好的创新引领型企业",
          publishTime: "2024-07-23 10:01",
          source: "科技企业与现代服务业科技处",
          url: "https://wap.kjt.henan.gov.cn/2024/07-23/3025704.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于2024年度河南省首版次软件产品认定结果的公示",
          content: "根据《河南省工业和信息化厅 河南省财政厅关于开展2024年度河南省首版次软件产品申报工作的通知》（豫工信联信软〔2024〕75号）工作部署，经企业自愿申报、各地审核推荐、专家评审，拟认定“iSDC边缘智能数据采集软件[简称:iSDC]V1.0”等16项软件产品为2024年度河南省首版次软件产品，现予以公示，欢迎社会各界监督。如有异议，请于公示期内，以书面材料向省工业和信息化厅反馈。",
          publishTime: "2024-08-05 10:58",
          source: "厅信息化与软件服务业处",
          url: "https://gxt.henan.gov.cn/2024/08-05/3031999.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于印发《河南省创新型企业梯次培育实施方案（2024-2026年）(试行）》的通知",
          content: "各省辖市科技局，济源示范区、航空港区科技管理部门，县（市）科技管理部门，各国家高新区管委会，各有关单位：为进一步强化企业科技创新主体地位，优化完善创新型企业梯次培育体系，促进全省创新型企业高质量发展,现将《河南省创新型企业梯次培育实施方案（2024-2026年）(试行）》印发给你们，请认真组织实施。",
          publishTime: "2024-07-02 16:17",
          source: "科技企业与现代服务业科技处",
          url: "https://wap.kjt.henan.gov.cn/2024/07-02/3016214.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于2024年专精特新重点“小巨人”企业 拟推荐名单的公示",
          content: "根据《河南省工业和信息化厅 河南省财政厅关于组织开展2024年重点支持专精特新“小巨人”企业遴选推荐工作的通知》（豫工信联企业〔2024〕146号），经企业自愿申报，各省辖市、济源示范区、航空港区工业和信息化主管部门、财政主管部门联合推荐，省工业和信息化厅、财政厅组织专家评审，现将拟向工业和信息化部、财政部推荐的企业名单予以公示。",
          publishTime: "2024-07-29 21:19",
          source: "厅中小企业局",
          url: "https://m.henan.gov.cn/2024/07-23/3025779.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于2024年度河南省重大科技专项项目任务书签订及后续管理事项的通知",
          content: "各有关单位：\n" +
              "\n" +
              "　　根据《河南省科学技术厅 河南省财政厅关于组织申报2024年度河南省重大科技专项的通知》（豫科项〔2023〕49号），为强化项目绩效管理，确保完成目标任务，现将2024年度省重大科技专项项目任务书签订及后续管理有关事项通知如下：",
          publishTime: "2024-07-30 12:41",
          source: "科技项目统筹推进处",
          url: "https://wap.kjt.henan.gov.cn/2024/07-30/3029212.html"
        },
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "梯次培育创新型企业！河南省启动科技型中小企业申报入库工作",
          content: "打造创新型企业金字塔梯次队伍，建立“创新龙头企业—瞪羚企业—高新技术企业—科技型中小企业”梯次培育体系。近日，省科技厅发布《河南省创新型企业梯次培育实施方案（2024—2026年）（试行）》（以下简称《方案》），提升各类企业创新能力，塑造新动能新优势。",
          publishTime: "2024年07月23日",
          source: "河南省人民政府",
          url: "https://m.henan.gov.cn/2024/07-23/3025779.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于2024年度国家绿色制造拟推荐名单的公示",
          content: "根据《河南省工业和信息化厅办公室关于组织推荐2024年度国家级绿色制造名单的预通知》（豫工信办节〔2024〕112号），经企业和园区自主申报、地方工信部门审核推荐，专家评审等程序，拟推荐郑州煤矿机械集团股份有限公司等80家绿色工厂",
          publishTime: "2024-07-23 16:54",
          source: "厅节能与综合利用处",
          url: "https://gxt.henan.gov.cn/2024/07-23/3025925.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省工业和信息化厅 河南省科学技术厅 河南省财政厅 国家税务总局河南省税务局 关于做好2024年度享受增值税加计抵减政策先进制造业企业名单制定工作的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化、科技、财政、税务主管部门：\n" +
              "\n" +
              "　　根据《财政部 税务总局关于先进制造业企业增值税加计抵减政策的公告》（财政部 税务总局公告2023年第43号）、《工业和信息化部办公厅 财政部办公厅 国家税务总局办公厅关于2024年度享受增值税加计抵减政策的先进制造业企业名单制定工作有关事项的通知》（工信厅联财函〔2024〕248号）有关规定",
          publishTime: "2024-07-22 09:23",
          source: "厅财务审计处",
          url: "https://gxt.henan.gov.cn/2024/07-22/3024967.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "2024年拟认定郑州市企业技术中心名单公示",
          content: "强化企业创新主体地位，提升企业创新能力，根据《郑州市企业技术中心认定管理办法》（郑工信〔2021〕57号），市工信局组织开展了2024年市级企业技术中心认定工作。经企业申报、区县（市）工信部门推荐、专家评审和现场核查等程序",
          publishTime: "2024-07-16 10:11",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/8543048.jhtml"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "2024年度河南省科学技术奖受理项目公示",
          content: "2024年我厅共收到各单位或专家提名的河南省自然科学奖、技术发明奖、科学技术进步奖项目788项，经形式审查，733项形式审查合格，予以受理。现将受理的733项候选项目（含项目名称、提名奖种、主要完成人、主要完成单位、提名者）在河南省科学技术厅网站（https://kjt.henan.gov.cn）和河南省科技奖励工作网（http://jl.hnkjt.gov.cn）同时公示。",
          publishTime: "2024-07-19 13:24",
          source: "省科学技术奖励工作办公室、审计处",
          url: "https://wap.kjt.henan.gov.cn/2024/07-19/3024477.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于开展2024年度全省科技型中小企业评价工作的通知",
          content: "各省辖市科技局，济源示范区、郑州航空港经济综合实验区管委会、各县（市、区）科技管理部门，各国家高新区管委会，各有关单位：",
          publishTime: "2024-07-16 16:07",
          source: "科技企业与现代服务业科技处",
          url: "https://wap.kjt.henan.gov.cn/2024/07-16/3022798.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "工业和信息化部办公厅关于开展2024年粘胶纤维企业公告申报工作的通知",
          content: "各省、自治区、直辖市及计划单列市、新疆生产建设兵团工业和信息化主管部门：\n" +
              "根据《粘胶纤维行业规范条件（2024版）》及《粘胶纤维企业规范条件公告管理办法》（中华人民共和国工业和信息化部公告2024年第7号）有关要求，现组织开展2024年粘胶纤维企业公告申报工作。有关事项通知如下：",
          publishTime: "2024年7月5日",
          source: "工业和信息化部办公厅",
          url: "https://wap.miit.gov.cn/zwgk/zcwj/wjfb/tz/art/2024/art_f693ee61174145299879ae1710831177.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "工业和信息化部办公厅关于开展2024年度科技型中小企业评价工作的通知",
          content: "各省、自治区、直辖市及计划单列市科技型中小企业工作主管部门：为深入贯彻落实党中央、国务院关于推进新型工业化的决策部署，以科技创新推动产业创新，促进中小企业专精特新发展，根据《科技型中小企业评价办法》（国科发政〔2017〕115号）和《科技型中小企业评价服务工作指引》",
          publishTime: "2024年6月25日",
          source: "工业和信息化部办公厅",
          url: "https://wap.miit.gov.cn/zwgk/zcwj/wjfb/tz/art/2024/art_e84d6ccec0044b8cb216ee290d1de7da.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于印发郑州市绿色低碳工厂评价管理办法（试行）的通知",
          content: "各开发区、区县（市）工信部门，各有关单位：\n" +
              "\n" +
              "为深入贯彻绿色低碳发展理念，落实《郑州市制造业绿色低碳高质量发展三年行动计划（2023-2025）》，按照国家绿色制造梯度培育管理有关要求，进一步完善本市绿色制造体系建设，促进工业绿色低碳化转型，强化绿色低碳制造示范引领",
          publishTime: "2024-01-15 17:11",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/zcfg/8294520.jhtml"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省工业和信息化厅 关于公布河南省工业领域网络和数据安全技术服务支撑单位名单的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门：\n" +
              "\n" +
              "　　根据《河南省工业和信息化厅办公室关于组织开展河南省工业领域网络和数据安全技术服务支撑单位及专家遴选工作的通知》（豫工信办信软〔2024〕63号），经单位自主申报、各地工业和信息化主管部门推荐、专家评审和网上公示",
          publishTime: "2024-07-08 17:33",
          source: "厅信息化与软件服务业处",
          url: "https://gxt.henan.gov.cn/2024/07-08/3018798.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省工业和信息化厅 关于公布2024年河南省质量标杆名单的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门，各有关单位：\n" +
              "\n" +
              "　　为深入贯彻国家大数据战略，加快实施省委省政府数字化转型战略部署，根据《河南省大数据产业发展行动计划（2022—2025年）》有关要求，省工业和信息化厅拟在全省开展企业首席数据官（Chief Data Officer，以下简称“CDO”）试点工作。现将有关事项通知如下：",
          publishTime: "2024-07-11 17:56",
          source: "厅技术创新处",
          url: "https://gxt.henan.gov.cn/2024/07-11/3020574.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省工业和信息化厅办公室 关于组织开展企业首席数据官试点申报工作的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门，各有关单位：\n" +
              "\n" +
              "　　为深入贯彻国家大数据战略，加快实施省委省政府数字化转型战略部署，根据《河南省大数据产业发展行动计划（2022—2025年）》有关要求，省工业和信息化厅拟在全省开展企业首席数据官（Chief Data Officer，以下简称“CDO”）试点工作。现将有关事项通知如下：",
          publishTime: "2024-07-16 17:09",
          source: "厅大数据产业发展局",
          url: "https://gxt.henan.gov.cn/2024/07-16/3022859.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "2024年度郑州市工程技术研究中心和重点实验室通过形式审查名单公示",
          content: "依据《郑州市工程技术研究中心和重点实验室建设与管理办法》（郑科规〔2021〕4号）及《关于组织申报2024年度郑州市工程技术研究中心和重点实验室的通知》，经单位申报、区县（市）科技主管部门推荐、形式审查、研发统计核查、信用核查等环节，共有111家工程技术研究中心和46家重点实验室通过形式审查。",
          publishTime: "2024-07-11 15:40:29 ",
          source: "郑州市科学技术局资源处",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/8534137.jhtml"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "郑州市工业和信息化局等11部门关于印发郑州市推动工业领域设备更新实施方案的通知",
          content: "各开发区管委会，各区县（市）人民政府，市人民政府各部门，各有关单位：\n" +
              "\n" +
              "《郑州市推动工业领域设备更新实施方案》已经市政府同意，现印发给你们，请认真贯彻执行。",
          publishTime: "2024年6月25日",
          source: "郑州市工业和信息化局等11部门",
          url: "https://public.zhengzhou.gov.cn/D0105Y/8527109.jhtml"
        },*/
/*        {
          title: "关于举办第十三届中国创新创业大赛河南赛区暨第十六届河南省创新创业大赛的通知",
          content: "各省辖市科技局、济源示范区，航空港区管委会主管部门，各县(市)科技主管部门，各国家高新区管委会，各有关单位:为落实创新驱动发展战略，强化企业科技创新主体地位，推动科技创新和产业创新深度融合，发展新质生产力，助力推进新型工业化，根据《工信部关于举办第十三届中国创新创业大赛的",
          publishTime: "2024-07-010 11:00",
          source: "河南省科学技术厅",
          url: "./#/detail?id=5"
        },*/
/*        {
          title: "关于2023年郑州市制造业高质量发展专项资金拟支持项目（第二批）的公示",
          content: "据《郑州市人民政府关于印发郑州市进一步加强制造业高质量发展若干政策的通知》（郑政〔2023〕23号）、《郑州市工业和信息化局  郑州市财政局关于印发<郑州市进一步加强制造业高质量发展若干政策实施细则>的通知》（郑工信运行〔2023〕13号）和《郑州市工业和信息化局  郑州市财政局关于组织开展2023年郑州市制造业高质量发展专项资金申报工作的通知》（郑工信运行〔2023〕14号）要求，经企业自愿申报、区县（市）初审推荐、市级初审、专家组评审、第三方审计等程序，对申报战略性新兴企业培育奖励",
          publishTime: "2024-07-04 11:00",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/8520396.jhtml"
        },*/
/*        {
          title: "工业和信息化部办公厅 财政部办公厅 国务院国资委办公厅\n" +
              "关于开展2024年制造业人才支持计划申报推荐工作的通知",
          content: "各省、自治区、直辖市及计划单列市、新疆生产建设兵团工业和信息化主管部门、财政厅（局），各中央企业:\n" +
              "\n" +
              "为深入贯彻党的二十大和二十届二中全会精神，全面落实习近平总书记关于做好新时代人才工作的重要思想和关于推进新型工业化、制造强国等的重要论述，现组织开展2024年制造业人才支持计划申报推荐工作。有关事项通知如下：",
          publishTime: "2024-06-25 11:38",
          source: "人事教育司",
          url: "https://www.miit.gov.cn/jgsj/rjj/rcgz/art/2024/art_826d5a3a4e9e48fcb3bc52d503e8d838.html"
        },*/
/*        {
          title: "关于印发《河南省创新型企业梯次培育实施方案（2024-2026年）(试行）》的通知",
          content: "各省辖市科技局，济源示范区、航空港区科技管理部门，县（市）科技管理部门，各国家高新区管委会，各有关单位：",
          publishTime: "2024-07-02 16:17",
          source: "科技企业与现代服务业科技处",
          url: "https://wap.kjt.henan.gov.cn/2024/07-02/3016214.html"
        },*/
/*        {
          title: "工业和信息化部办公厅关于开展2024年度科技型中小企业评价工作的通知",
          content: "各省、自治区、直辖市及计划单列市科技型中小企业工作主管部门：为深入贯彻落实党中央、国务院关于推进新型工业化的决策部署，以科技创新推动产业创新，促进中小企业专精特新发展，根据《科技型中小企业评价办法》（国科发政〔2017〕115号）和《科技型中小企业评价服务工作指引》（国科火字〔2022〕67号）有关要求，现就开展2024年度科技型中小企业评价工作通知如下：",
          publishTime: "2024年06月27日",
          source: "工业和信息化部办公厅",
          url: "http://www.chinatorch.gov.cn/kjb/tzgg/202406/a7ebaec042ce416fbaaccfcdd8cf8708.shtml"
        },*/
/*        {
          title: "河南省工业和信息化厅关于公布2024年度河南省数字化能碳管理中心名单的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门：\n" +
              "\n" +
              "　　按照《河南省工业和信息化厅办公室关于遴选2024年度河南省数字化能碳管理中心的通知》（豫工信办节〔2024〕67号）要求，经企业自主申报、各地工信部门推荐",
          publishTime: "2024-06-26 11:01",
          source: "节能与综合利用处",
          url: "https://gxt.henan.gov.cn/2024/06-26/3013545.html"
        },*/
/*        {
          title: "河南省科学技术厅 国家税务总局河南省税务局 关于组织开展企业研究开发费用税前加计扣除项目填报鉴定工作的通知",
          content: "各省辖市科技局、税务局，济源示范区、航空港区科技、税务管理部门，县（市、区）科技、税务管理部门，省税务局第三税务分局",
          publishTime: "2024-06-25 09:25",
          source: "科技企业与现代服务业科技处",
          url: "https://wap.kjt.henan.gov.cn/2024/06-25/3012768.html"
        },*/
/*        {
          title: "2024年河南省质量标杆名单公示",
          content: "　根据《河南省工业和信息化厅办公室关于开展2024年河南省制造业质量提升活动的通知》（豫工信办创新〔2024〕61号）安排，经企业自愿申报，各省辖市、济源示范区、航空港区工业和信息化主管部门初审推荐，我厅组织专家评审，拟确定70项典型经验为2024年河南省质量标杆",
          publishTime: "2024-06-21 21:43",
          source: "技术创新处",
          url: "https://gxt.henan.gov.cn/2024/06-21/3011914.html"
        },*/
/*        {
          title: "河南省工业和信息化厅办公室关于开展 2024年河南省制造业单项冠军企业遴选工作的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门:为引导我省制造业企业深耕细作、创新发展，根据《工业和信息化部关于印发<制造业单项冠军企业认定管理办法>的通知》",
          publishTime: "2024-06-21 10:14",
          source: "河南省工业和信息化厅规划处",
          url: "./#/detail?id=4"
        },*/
/*        {
          title: "河南省工业和信息化厅关于2024年河南省制造业单项冠军企业名单的公示",
          content: "为贯彻落实《河南省制造业单项冠军企业培育提升专项行动方案》和《河南省工业和信息化厅办公室关于开展2024年河南省制造业单项冠军企业遴选工作的通知》（豫工信办规〔2024〕75号），省工业和信息化厅组织开展了2024年河南省制造业单项冠军企业遴选工作，已完成相关评审，现将通过评审的企业名单予以公示。",
          publishTime: "2024-06-21 10:14",
          source: "河南省工业和信息化厅规划处",
          url: "https://gxt.henan.gov.cn/2024/06-21/3011404.html"
        },*/
/*        {
          title: "关于组织开展2022年度和2023年度郑州市加快新材料产业发展专项资金申报工作的通知",
          content: "各开发区、区县（市）工信部门、财政部门，有关企业：\n" +
              "\n" +
              "为贯彻落实《郑州市人民政府办公厅关于加快新材料产业发展的实施意见》（郑政办〔2022〕56号），根据《郑州市工业和信息化局 郑州市财政局关于印发〈郑州市加快新材料产业发展若干政策实施细则〉的通知》（郑工信原材料〔2024〕8号），郑州市工业和信息化局、郑州市财政局决定组织开展2022年度和2023年度郑州市加快新材料产业发展专项资金申报工作。现将有关通知如下：",
          publishTime: "2024-06-13 16:08",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/8474910.jhtml"
        },*/
/*        {
          title: "关于开展郑州市先进制造业产业链“链主”企业（第二批）申报及（第一批）复核工作的通知",
          content: "区内企业：\n" +
              "\n" +
              "现将《关于开展郑州市先进制造业产业链“链主”企业（第二批）申报及（第一批）复核工作的通知》转发给你们，请符合条件的企业积极申报，企业按照“链主”企业评价管理办法和通知要求编制复核或申报材料，于6月26日17点前将申报材料（纸质版，一式二份）",
          publishTime: "2024-06-06 15:16 ",
          source: "郑州高新技术产业开发区管理委员会",
          url: "https://www.zzgx.gov.cn/tzgg/8461180.jhtml"
        },*/
/*        {
          title: "郑州市人民政府\n" +
              "关于印发郑州市支持人工智能创新发展\n" +
              "若干政策措施的通知",
          content: "各开发区管委会，各区县（市）人民政府，市人民政府各部门，各有关单位：\n" +
              "\n" +
              "现将《郑州市支持人工智能创新发展若干政策措施》印发给你们，请认真贯彻执行。",
          publishTime: "2024年5月22日",
          source: "郑州市人民政府",
          url: "https://public.zhengzhou.gov.cn/D0104X/8431444.jhtml"
        },*/
/*        {
          title: "2024年河南省数字领航企业中小企业数字化转型标杆拟确定名单公示",
          content: "根据《河南省加快数字化转型推动制造业高端化智能化绿色化发展行动计划（2023—2025年）》（豫政〔2023〕32号）、《河南省制造强省建设领导小组办公室关于印发2024年河南省加快数字化转型推动制造业高端化智能化绿色化发展工作方案的通知》（豫制造强省办〔2024〕13号）、《河南省工业和信息化厅办公室关于开展2024年制造业数字化转型示范遴选工作的通知》（豫工信办〔2024〕30号）等文件要求",
          publishTime: "2024-05-27 22:12",
          source: "厅数字化与未来产业处",
          url: "https://gxt.henan.gov.cn/2024/05-27/2999187.html"
        },*/
/*        {
          title: "河南省科学技术厅 河南省财政厅 关于开展2024年度中央引导地方科技发展资金项目储备库建设的通知",
          content: "为贯彻落实郑州市委市政府关于率先构建一流创新生态 建设国家创新高地的意见，培育一流创新主体，持续发展壮大高新技术企业队伍，加快推进高新技术企业提质增量，市科技局起草了《郑州市高新技术企业培育三年倍增计划实施方案（2024-2026年）（征求意见稿）》。现面向社会公开征求意见。",
          publishTime: "2024-05-27 10:41",
          source: "科技成果转化与区域创新处（省技术市场管理办公室、郑洛新国家自主创新示范区工作办公室）",
          url: "https://wap.kjt.henan.gov.cn/2024/05-27/2998896.html"
        },*/
/*        {
          title: "关于《郑州市高新技术企业培育三年倍增计划实施方案（2024-2026年）》公开征求意见的公告",
          content: "为贯彻落实郑州市委市政府关于率先构建一流创新生态 建设国家创新高地的意见，培育一流创新主体，持续发展壮大高新技术企业队伍，加快推进高新技术企业提质增量，市科技局起草了《郑州市高新技术企业培育三年倍增计划实施方案（2024-2026年）（征求意见稿）》。现面向社会公开征求意见。",
          publishTime: "2024-05-20 17:25 ",
          source: "郑州市科学技术局高新处",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/8420244.jhtml"
        },*/
/*        {
          title: "关于2023年郑州市制造业高质量发展专项资金拟支持项目（第一批）和进入第三方审计程序项目的公示",
          content: "根据《郑州市人民政府关于印发郑州市进一步加强制造业高质量发展若干政策的通知》（郑政〔2023〕23号）、《郑州市工业和信息化局  郑州市财政局关于印发<郑州市进一步加强制造业高质量发展若干政策实施细则>的通知》（郑工信运行〔2023〕13号）",
          publishTime: "2024-05-13 10:57",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/8405621.jhtml?_refluxos=a10"
        },*/
/*        {
          title: "关于2024年省级制造业高质量发展专项资金 （中小企业类）拟支持项目的公示",
          content: "根据《河南省工业和信息化厅 河南省财政厅关于开展2024年省级制造业高质量发展专项资金项目（中小企业类）申报工作的通知》（豫工信联企业〔2023〕158号）要求，我厅联合省财政厅组织开展了2024",
          publishTime: "2024-05-13 10:55",
          source: "厅财务审计处",
          url: "https://gxt.henan.gov.cn/2024/05-13/2990066.html"
        },*/
/*        {
          title: "关于组织申报2024年度郑州市工程技术研究中心和重点实验室的通知",
          content: "各开发区、区县（市）科技主管部门，各有关单位：\n" +
              "\n" +
              "为深入贯彻落实“创新驱动、科教兴市、人才强市”战略，推动我市创新平台建设，依据《郑州市工程技术研究中心和重点实验室建设与管理办法》（郑科规〔2021〕4号），市科技局决定开展2024年度郑州市工程技术研究中心和重点实验室申报工作。现将有关事宜通知如下。",
          publishTime: " 2024-05-06 17:38:08",
          source: "郑州市科学技术局资源处",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/8386276.jhtml"
        },*/
/*        {
          title: "关于2024年度河南省省级绿色制造体系建设评审结果的公示",
          content: "　根据《河南省工业和信息化厅办公室关于组织推荐2024年度省级绿色制造名单及前八批绿色制造名单填报动态管理表的通知》《河南省工业和信息化厅河南省住房和城乡建设厅关于组织推荐2024年度省级绿色制造名单的通知》的要求，经过企业和园区自主申报、地方工信部门审核推荐",
          publishTime: " 2024-05-06 17:48",
          source: "厅节能与综合利用处",
          url: "https://gxt.henan.gov.cn/2024/05-06/2987115.html"
        },*/
/*        {
          title: "2024年中央预算内资金重点支持方向和领域",
          content: "2024年中央预算内投资主要支持粮食安全、能源安全、产业链供应链安全、城市基础设施及保障性安居工程基础设施、生态环境保护、交通物流重大基础设施、社会事业及其他重点领域等八个方面包括产粮大县公共服务基础设施、粮食仓储设施、支撑性调节煤电、农村电网改造、新引擎赛道、战略性矿产资源保障供应等34个领域。",
          publishTime: " 2024-04-20 15:22",
          source: "乡村振兴生态产业研究中心",
          url: "./#/detail?id=3"
        },*/
/*        {
          title: "5月1日起施行！河南省人民政府令第225号",
          content: "对于专精特新企业，河南将拿出满满的诚意予以支持。日前，省长王凯签署第225号省政府令，《河南省专精特新企业培育支持办法(试行)》自2024年5月1日起施行。",
          publishTime: " 2024-04-24 11:00",
          source: "河南税务局",
          url: "https://mp.weixin.qq.com/s/xtcKqfaX2xhxJrIpcOWjRg"
        },*/
/*        {
          title: "郑州航空港经济综合实验区管理委员会关于印发郑州航空港经济综合实验区科技创新驱动高质量发展若干政策措施的通知",
          content: "全区各委（部）、局、办，各乡镇（办事处），各有关单位：\n" +
              "\n" +
              "　　现将《郑州航空港经济综合实验区科技创新驱动高质量发展若干政策措施》印发给你们，请认真贯彻执行。",
          publishTime: "2024年04月16日",
          source: "郑州航空港经济综合实验区管理委员会",
          url: "https://m.zzhkgq.gov.cn/2024/04-16/2979613.html"
        },*/
/*        {
          title: "关于2024年度河南省科学技术奖提名工作的通知",
          content: "各有关单位、专家：\n" +
              "\n" +
              "　　为做好2024年度河南省科学技术奖提名工作，贯彻落实省委省政府重大科技创新战略部署，强化科技奖励对全省高质量发展的支撑引领作用，根据《国家科学技术奖提名办法》（国科发奖〔2023〕225号）和《河南省深化科技奖励制度改革方案》（豫政办〔2019〕32号）的精神。",
          publishTime: "2024-04-23 20:00",
          source: "科技监督与诚信建设处",
          url: "https://wap.kjt.henan.gov.cn/2024/04-23/2982780.html"
        },*/
/*        {
          title: "2024年度河南省重点研发专项拟立项公示",
          content: "根据《河南省科技计划项目管理办法（试行）》和《河南省科技计划项目信息公开管理办法》等有关要求，现将2024年度河南省重点研发专项拟立项项目予以公示，公示期5个工作日（2024年4月18日—4月24日）。",
          publishTime: "2024-04-18 17:55",
          source: "科技项目统筹推进处",
          url: "https://wap.kjt.henan.gov.cn/2024/04-18/2980704.html"
        },*/
/*        {
          title: "河南省科学技术厅 河南省财政厅 河南省教育厅 关于开展2023年度科技成果转化年度报告工作的通知",
          content: "各省辖市科技局、财政局、教育局，济源示范区、航空港区科技、财政、教育主管部门，省直有关部门，省属高等学校，各有关单位：",
          publishTime: "2024-04-19 17:03",
          source: "科技成果转化与区域创新处",
          url: "https://wap.kjt.henan.gov.cn/2024/04-19/2981120.html"
        },*/
/*        {
          title: "河南省工业和信息化厅办公室 关于组织开展第六批省级工业设计中心和 产业园区认定工作的通知",
          content: "区内各有关企业：按照《郑州高新技术产业开发区管理委员会关于兑现2023年度第一批郑州高新区加快推进高质量发展若干政策措施相关补贴的决定》（郑开管文〔2024〕14号）要求，现向高新区2023年度第一批获得高质量发展若干政策奖补资金的企业，拨付区级奖补资金。",
          publishTime: "2024-03-29 17:30",
          source: "厅技术创新处",
          url: "https://gxt.henan.gov.cn/2024/03-29/2967798.html"
        },*/
/*        {
          title: "郑州高新区关于拨付2023年度第一批郑州高新区加快推进高质量发展若干政策措施区级奖补资金的通知",
          content: "区内各有关企业：按照《郑州高新技术产业开发区管理委员会关于兑现2023年度第一批郑州高新区加快推进高质量发展若干政策措施相关补贴的决定》（郑开管文〔2024〕14号）要求，现向高新区2023年度第一批获得高质量发展若干政策奖补资金的企业，拨付区级奖补资金。",
          publishTime: "2024-03-07 11:08 ",
          source: "郑州高新技术产业开发区管理委员会",
          url: "https://www.zzgx.gov.cn/tzgg/8255047.jhtml"
        },*/
/*        {
          title: "关于开展2024年度郑州市科技型企业评价工作的通知",
          content: "各开发区、区县（市）科技主管部门，各有关单位：\n" +
              "\n" +
              "根据《郑州市科技型企业评价管理办法》（郑科规〔2020〕2号）（以下简称《评价办法》）有关要求，市科技局决定组织开展2024年度郑州市科技型企业评价工作，现将有关事宜通知如下：",
          publishTime: "2024-03-04 16:40:20 ",
          source: "高新处 ",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/8246121.jhtml"
        },*/
/*        {
          title: "关于征集2024年度河南省科学技术奖申报意向的通知",
          content: "各开发区、区县（市）科技主管部门，各企事业单位：2024年全省科学技术奖提名工作预计省科技厅将于3月底正式启动，我市计划4月中旬前完成受理及推荐上报工作，现拟提前谋划开展意向征集工作，有关事宜通知如下：",
          publishTime: "2024-02-27 11:32:18 ",
          source: "厅成果处",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/8232255.jhtml"
        },*/
/*        {
          title: "河南省工业和信息化厅 河南省财政厅 关于印发河南省首台（套）重大技术装备推广应用指导目录（2024年版）的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门、财政部门：\n" +
              "\n" +
              "　　为贯彻党的二十大关于实施重大技术装备攻关工程的决策部署，落实《河南省人民政府关于印发河南省重大技术装备攻坚方案（2023—2025年）的通知》（豫政〔2023〕42号）文件精神",
          publishTime: "2024-02-27 11:09",
          source: "厅装备工业处",
          url: "https://gxt.henan.gov.cn/2024/02-27/2954106.html"
        },*/
/*        {
          title: "关于2024年省级制造业高质量发展专项资金 （不含中小企业方向）拟支持项目的公示",
          content: "2024年1月23日至24日，省工业和信息化厅、省财政厅联合组织专家对2024年省级制造业高质量发展专项资金疑似重复支持项目（不含中小企业方向）组织召开了专家答疑会。",
          publishTime: "2024-02-18 17:00",
          source: "郑州市信息化促进会",
          url: "https://mp.weixin.qq.com/s/6hpJSXxrKoJZUcBeSRBoTw"
        },*/
/*        {
          title: "关于印发《中央引导地方科技发展资金管理办法》的通知",
          content: "各省、自治区、直辖市、计划单列市财政厅（局）、科技厅（委、局），新疆生产建设兵团财政局、科技局：",
          publishTime: "2023年12月28日",
          source: "中华人民共和国财政部",
          url: "http://jkw.mof.gov.cn/zhengcefabu/202401/t20240130_3927747.htm"
        },*/
/*        {
          title: "河南省工业和信息化厅办公室 关于组织推荐2024年度省级绿色制造名单及前八批绿色制造名单填报动态管理表的通知",
          content: "各省辖市、济源示范区、航空港区、各县（市）工业和信息化主管部门：\n" +
              "\n" +
              "　　为贯彻落实《“十四五”工业绿色发展规划》《工业领域碳达峰实施方案》《河南省制造业绿色低碳高质量发展三年行动计划（2023-2025年）》，推动我省工业绿色低碳转型高质量发展，现组织开展2024年度省级绿色制造名单推荐工作。有关事项通知如下：",
          publishTime: "2024-01-23 16:07",
          source: "厅节能与综合利用处",
          url: "https://gxt.henan.gov.cn/2024/01-23/2891477.html"
        },*/
/*        {
          title: "关于郑州市首批工业设计中心、工业设计产业园区（楼宇）拟认定名单的公示",
          content: "按照《郑州市市级工业设计中心、工业设计产业园区（楼宇）认定管理办法（试行）》（郑工信〔2023〕188号）规定，我局开展了首批市级工业设计中心、产业园区（楼宇）认定工作。",
          publishTime: "2024-01-15 10:39",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/8153876.jhtml"
        },*/
/*        {
          title: "工业和信息化部办公厅关于公布2023年新一代信息技术与制造业融合发展示范名单的通知",
          content: "各省、自治区、直辖市、计划单列市及新疆生产建设兵团工业和信息化主管部门，有关中央企业，有关单位：按照《工业和信息化部办公厅关于组织开展2023年新一代信息技术与制造业融合发展示范申报工作的通知》（工信厅信发函〔2023〕243号）要求，经企业自主申报、地方推荐、专家评审、网上公示等环节，确定了184个2023年新一代信息技术与制造业融合发展示范项目（名单见附件），现予以公布。请各地结合实际，认真抓好示范推广工作。",
          publishTime: "2024-01-10",
          source: "工业和信息化部办公厅",
          url: "https://www.miit.gov.cn//zwgk/zcwj/wjfb/tz/art/2024/art_815938c9abc14eaa93079d538f7d6e98.html"
        },*/
/*        {
          title: "河南省工业和信息化厅 关于印发河南省省级工业设计中心和 产业园区认定管理办法的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门：\n" +
              "\n" +
              "　　现将《河南省省级工业设计中心和产业园区认定管理办法》印发给你们，请结合实际认真贯彻落实。",
          publishTime: "2024-01-10 16:53",
          source: "厅技术创新处",
          url: "https://gxt.henan.gov.cn/2024/01-10/2882766.html"
        },*/
/*        {
          title: "关于2023年度拟认定河南省工程技术研究中心名单的公示",
          content: "根据《河南省科技创新平台建设与管理办法（试行）》《关于组织申报2023年河南省工程技术研究中心的通知》要求，经单位申报、主管部门推荐、专家论证和研究等程序，拟认定810家河南省工程技术研究中心。现将名单予以公示，公示期5个工作日（2024年1月5日-2024年1月11日）。公示期间若有异议，请以书面形式向河南省科技厅实名反映。",
          publishTime: "2024-01-04 10:51",
          source: "河南省科学技术厅实验室与平台基地建设处",
          url: "https://wap.kjt.henan.gov.cn/2024/01-04/2879166.html"
        },*/
/*        {
          title: "郑州市工业和信息化局   郑州市财政局于印发《郑州市进一步加强制造业高质量发展若干政策实施细则》的通知",
          content: "各开发区、区县（市）工信部门、财政部门：为贯彻落实《郑州市人民政府进一步加强制造业高质量发展的若干政策》（郑政〔2023〕23号），郑州市工业和信息化局、郑州市财政局研究制定了实施细则，现印发给你们，请遵照执行。",
          publishTime: "2023-12-28 16:39",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/8109425.jhtml"
        },*/
/*        {
          title: "郑州市工业和信息化局  郑州市财政局关于组织开展2023年郑州市制造业高质量发展专项资金申报工作的通知",
          content: "各开发区、区县（市）工信部门、财政部门，有关企业：为贯彻落实《郑州市人民政府关于进一步加强制造业高质量发展的若干政策》（郑政〔2023〕23号），根据《郑州市工业和信息化局  郑州市财政局印发关于进一步加强制造业高质量发展若干政策实施细则的通知》（郑工信运行〔2023〕13号），郑州市工业和信息化局、郑州市财政局决定组织开展2023年郑州市制造业高质量发展专项资金申报工作。现将有关事项通知如下",
          publishTime: "2023-12-28 16:33",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/8109342.jhtml"
        },*/
/*        {
          title: "关于2023年河南省拟备案新型研发机构的公示",
          content: "根据《河南省新型研发机构备案和绩效考核办法》（豫科〔2023〕54号）和《关于开展2023年省新型研发机构备案工作的通知》（豫科实〔2023〕5号）精神，经单位申报、主管部门推荐、专家论证、厅长办公会研究等程序，拟备案哈工大郑州研究院等17家省新型研发机构。",
          publishTime: "2023-12-18 09:53",
          source: "实验室与平台基地建设处",
          url: "https://wap.kjt.henan.gov.cn/2023/12-18/2867697.html"
        },*/
/*        {
          title: "郑州市人民政府关于印发郑州市进一步加强制造业高质量发展若干政策的通知！",
          content: "为深入贯彻落实制造强国战略，进一步优化制造业高质量发展环境，推进新型工业化，加快建设国家先进制造业高地，特制定本政策。",
          publishTime: "2023年11月28日印发",
          source: "郑州市人民政府办公厅",
          url: "./#/detail?id=2"
        },*/
/*        {
          title: "关于公布第二批河南省创新联合体的通知",
          content: "各省辖市科技局，济源示范区、航空港区管委会科技管理部门，各县（市）科技管理部门，各有关单位：",
          publishTime: "2023-12-01 12:09",
          source: "科技企业与现代服务业科技处",
          url: "https://kjt.henan.gov.cn/2023/12-01/2857726.html"
        },*/
/*        {
          title: "关于河南省2023年第三批拟更名高新技术企业名单补充公示",
          content: "在河南省2023年度第三批拟更名高新技术企业公示期间，收到2家企业提出的异议。根据《高新技术企业认定管理办法》（国科发火〔2016〕32号）和《高新技术企业认定管理工作指引》（国科发火〔2016〕195号）等有关规定，经复核予以通过，现对申报更名的原河南思拓力测绘科技有限公司等2家高新技术企业予以公示。",
          publishTime: "2023-12-01 12:21",
          source: "科技企业与现代服务业科技处",
          url: "https://kjt.henan.gov.cn/2023/12-01/2857727.html"
        },*/
/*        {
          title: "关于2023年度河南省创新型中小企业拟公告名单的公示",
          content: "根据《河南省优质中小企业梯度培育管理实施细则（暂行）》（豫工信企业〔2023〕45号）、《河南省工业和信息化厅办公室关于开展2023年河南省创新型中小企业评价工作的通知》（豫工信办企业〔2023〕133号），经企业自愿申报，各省辖市、济源示范区、航空港区工业和信息化主管部门审核、实地核查和公示等程序，现将拟入选2023年度河南省创新型中小企业名单予以公示",
          publishTime: "2023-11-28 14:43",
          source: "厅中小企业局",
          url: "https://gxt.henan.gov.cn/2023/11-28/2855505.html"
        },*/
/*        {
          title: "107家上榜！第五批工业产品绿色设计示范企业名单公布",
          content: "工业和信息化部近日公布第五批工业产品绿色设计示范企业名单，107家企业榜上有名。要求示范企业切实发挥引领带动作用，不断加强绿色设计创新开发能力和管理水平；各地工业和信息化主管部门、有关中央企业加强对示范企业的指导和服务，结合实际继续做好示范企业的培育工作。工业和信息化部将加强对工业产品绿色设计示范企业名单的监督管理，完善名单动态管理机制",
          publishTime: "2023年11月9日",
          source: "工业和信息化部办公厅",
          url: "https://mp.weixin.qq.com/s/ym8b6VFVPk8ng3ay41L06w"
        },*/
/*        {
          title: "河南省工业和信息化厅 关于印发2023年河南省服务型制造 标杆企业（平台）名单的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门：根据《河南省工业和信息化厅办公室关于开展2023年服务型制造标杆遴选工作的通知》（豫工信办数字〔2023〕124号）要求，经企业自愿申报，各省辖市、济源示范区、航空港区工业和信息化主管部门初审推荐",
          publishTime: "2023-11-23 15:21",
          source: "厅数字化与未来产业处",
          url: "https://gxt.henan.gov.cn/2023/11-23/2852937.html"
        },*/
/*        {
          title: "《2024年度国家自然科学基金项目指南》征订通知",
          content: "各依托单位及有关部门：\n" +
              "\n" +
              "　　国家自然科学基金委员会主编的《2024年度国家自然科学基金项目指南》（以下简称《项目指南》）预计于2024年1月中旬正式出版，届时将以电子和纸质两种形式同步刊出。电子版可通过我委门户网站在线阅览。",
          publishTime: "2023-09-25",
          source: "国家自然科学基金委员会机关服务中心",
          url: "https://www.nsfc.gov.cn/publish/portal0/tab434/info90362.htm"
        },*/
/*        {
          title: "对河南省认定机构2023年认定报备的第一批高新技术企业拟进行备案的公示",
          content: "根据《高新技术企业认定管理办法》（国科发火〔2016〕32号）和《高新技术企业认定管理工作指引》（国科发火〔2016〕195号）有关规定，现将河南省认定机构2023年认定报备的第一批3472家高新技术企业（企业名单详见附件）进行备案公示，公示期为10个工作日。",
          publishTime: "2023年11月22日",
          source: "全国高新技术企业认定管理工作领导小组办公室",
          url: "http://www.innocom.gov.cn/gqrdw/c101426/202311/878519167de94603928cd72420848f53.shtml"
        },*/
/*        {
          title: "第五批服务型制造示范名单公示",
          content: "根据《关于开展2023年服务型制造示范遴选和评估评价工作的通知》（工信厅政法函﹝2023﹞42号），现将拟入选第五批服务型制造示范名单进行公示，请社会各界监督。如有异议，请在公示期内将意见书面反馈（单位提出意见需加盖公章）至工业和信息化部产业政策与法规司（产业融合发展处）。",
          publishTime: "2023年11月16日-2023年11月22日",
          source: "工业和信息化部产业政策与法规司",
          url: "https://www.miit.gov.cn/zwgk/wjgs/art/2023/art_5f8e6622a73e47c5a9d36c08385f94b4.html"
        },*/
/*        {
          title: "关于组织申报2023年郑州市工程研究中心的通知",
          content: "各开发区、各区县（市）发展改革部门：\n" +
              "\n" +
              "为推进实施创新驱动发展战略，按照《郑州市工程研究中心管理办法》（郑发改高技〔2016〕559号，以下简称“《办法》”）有关规定，我委决定组织开展2023年郑州市工程研究中心申报认定工作，现将有关事项通知如下：",
          publishTime: "2023-11-15 10:23",
          source: "市发展改革委",
          url: "https://fgw.zhengzhou.gov.cn/notice/7989194.jhtml"
        },*/
/*        {
          title: "河南省科学技术厅 河南省财政厅 关于申报2024年度河南省重大科技专项的通知",
          content: "各省辖市科技局、财政局，济源示范区管委会科技、财政管理部门，郑州航空港经济综合实验区，各县（市）科技、财政主管部门，国家高新区、郑州经济技术开发区管委会，省直有关部门，各有关单位：",
          publishTime: "2023-11-14 16:00",
          source: "科技项目统筹推进处",
          url: "https://wap.kjt.henan.gov.cn/2023/11-14/2847743.html"
        },*/
        /*       {
          title: "开封市专利转化专项计划拟奖补项目名单公示",
          content: "各有关单位：\n" +
              "\n" +
              "根据《河南省知识产权局 河南省财政厅关于印发〈河南省专利转化专项资金实施方案>的通知》（豫知〔2022〕55号）、《河南省知识产权局关于明确第二批专利转化专项计划项目的通知》",
          publishTime: "2023年11月8日",
          source: "开封市市场监督管理局",
          url: "http://scjg.kaifeng.gov.cn/news/11375.cshtml"
        },*/
/*        {
          title: "2024年省级制造业高质量发展专项资金优秀智能应用场景项目拟确定名单公示",
          content: "根据《河南省工业和信息化厅河南省财政厅关于开展2024年省级制造业高质量发展专项资金项目申报工作的通知》（豫工信联规〔2023〕157号），经各省辖市、济源示范区、航空港区、各县（市）工业和信息化主管部门联合同级财政部门初审和现场核查，省工业和信息化厅、省财政厅组织专家对各地推荐的优秀智能应用场景项目申报材料进行了评审",
          publishTime: "2023年11月7日",
          source: "厅数字化与未来产业处",
          url: "https://gxt.henan.gov.cn/2023/11-07/2843515.html"
        },*/
/*        {
          title: "河南省科学技术厅 河南省财政厅 关于组织申报2024年度河南省重点研发专项的通知",
          content: "各省辖市科技局、财政局，济源示范区管委会科技、财政管理部门，郑州航空港经济综合实验区，各县（市）科技、财政主管部门，国家高新区、郑州经济技术开发区管委会，省直有关部门，各有关单位：",
          publishTime: "2023-10-13 17:25",
          source: "科技项目统筹推进处",
          url: "https://wap.kjt.henan.gov.cn/2023/10-13/2830040.html"
        },*/
/*        {
          title: "关于对郑州市2021年首次认定的高新技术企业拟给予资金奖补的公示",
          content: "郑州市2021年首次认定高新技术企业的奖补核定工作已完成，根据相关规定，现将拟奖补名单予以公示，财政补助实际拨付额根据当年度市财政科技专项预算实际情况按统一比例核拨。公示期为2023年10月13日至10月19日。公示期间若有异议请向郑州市科技局实名反映。",
          publishTime: "2023-10-13 14:29:16 ",
          source: "资源处",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/7884025.jhtml"
        },*/
/*        {
          title: "关于组织申报2023年度郑州市重大科技专项项目的通知",
          content: "各开发区、区县（市）科技、财政主管部门，各有关单位：\n" +
              "\n" +
              "为深入实施创新驱动发展战略，提升自主创新能力，着力攻克一批产业核心、关键、共性技术，支撑产业发展，依据《郑州市重大科技创新项目管理办法》（郑科规〔2023〕2号）和《郑州市重大科技创新专项资金管理办法》（郑财科文〔2018〕21号），现将2023年度郑州市重大科技专项项目申报的有关事项通知如下：",
          publishTime: "2023-10-13 17:20:02  ",
          source: "高新处",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/7885367.jhtml"
        },*/
/*        {
          title: "2023年度“中原企业家领军人才” 拟推荐人选公示",
          content: "按照《中共河南省委人才工作领导小组办公室关于开展2023年度“中原英才计划（育才系列）”申报工作的通知》要求，我厅严格按照推荐程序，认真组织了2023年度“中原企业家领军人才”的初评推荐工作。经企业自愿申报、各地推荐、专家评审及征求相关省直部门意见，拟确定以下企业家为“中原企业家领军人才”推荐人选：",
          publishTime: "2023-10-13 16:58",
          source: "厅企业服务办公室",
          url: "https://gxt.henan.gov.cn/2023/10-13/2830024.html"
        },*/
/*        {
          title: "郑州市市长质量奖评定委员会秘书处 关于郑州市第九届市长质量奖候选单位和 市长质量奖提名奖候选单位的公示",
          content: "根据《郑州市市长质量奖管理办法》《郑州市市长质量奖评定工作规范》和市政府有关批复要求，郑州市第九届市长质量奖拟评选出8家市长质量奖、8家市长质量奖提名奖。经企业自愿申报、资格审查、材料评审、现场评审、综合评审等程序，郑州市第九届市长质量奖评定委员会评定出9家市长质量奖候选单位、9家市长质量奖提名奖候选单位，现将名单予以公示",
          publishTime: "2023-10-07 10:47:15 ",
          source: "郑州市市长质量奖评定委员会秘书处",
          url: "https://amr.zhengzhou.gov.cn/tzgg/7865868.jhtml"
        },*/
/*        {
          title: "关于拟推荐2024年省级制造业高质量发展专项资金项目的公示",
          content: "按照《河南省工业和信息化厅 河南省财政厅关于开展2024年省级制造业高质量发展专项资金项目申报工作的通知》（豫工信联规〔2023〕157号）要求，郑州市工信局会同郑州市财政局组织开展了2024年省级制造业高质量发展专项资金项目申报工作，经过企业申报、开发区（不包括航空港经济综合实验区）及市内六区初审推荐、组织评审审核，经研究，拟推荐申报2024年省级制造业高质量发展专项资金项目68个（技改示范类项目另行公示），现予以公示。如有异议，请以书面形式在公示期内实名向郑州市工业和信息化局、郑州市财政局反映。",
          publishTime: "2023-09-28 11:22",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/7863709.jhtml"
        },*/
/*        {
          title: "关于拟推荐2024年省级制造业高质量发展专项资金技改示范类项目的公示",
          content: "按照《河南省工业和信息化厅 河南省财政厅关于开展2024年省级制造业高质量发展专项资金项目申报工作的通知》（豫工信联规〔2023〕157号）要求，郑州市工信局会同郑州市财政局组织开展了2024年省级制造业高质量发展专项资金技改示范类项目申报工作，经过企业申报、开发区（不包括航空港经济综合实验区）及市内六区初审推荐、第三方审计等，经研究，拟推荐符合要求的8个项目申报2024年省级制造业高质量发展专项资金，另有巩义市、中牟县、新郑市的8个项目符合政策要求，拟向省工信厅、省财政厅推荐，现一并予以公示。如有异议，请以书面形式在公示期内实名向郑州市工信局、郑州市财政局反映",
          publishTime: "2023-09-28 10:06",
          source: "郑州市工业和信息化局",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/7864136.jhtml"
        },*/
/*        {
          title: "河南省人民政府\n" +
              "关于印发河南省建设制造强省三年行动计划\n" +
              "（2023—2025年）的通知",
          content: "各省辖市人民政府，济源示范区、航空港区管委会，省人民政府各部门：\n" +
              "\n" +
              "　　现将《河南省建设制造强省三年行动计划（2023—2025年）》印发给你们，请认真组织实施。",
          publishTime: "2023年08月15日",
          source: "河南省人民政府",
          url: "https://m.henan.gov.cn/2023/08-15/2797207.html"
        },*/
/*        {
          title: "河南省知识产权维权保护中心关于开展河南省知识产权强企培育备案工作的通知",
          content: "各省辖市、济源示范区、航空港区知识产权管理部门：\n" +
              "\n" +
              "为深入实施知识产权战略，全面提升我省创新驱动发展能力和企业核心竞争力，加快形成一批拥有自主知识产权、具备行业竞争优势的知识产权强企，助推我省产业转型升级和经济高质量发展，现组织开展年度省知识产权强企培育备案工作。现将有关事宜通知如下：",
          publishTime: "2023-08-14",
          source: "河南省知识产权局",
          url: "http://zjxx.hnpatent.gov.cn/home/detail/2/11800.shtml"
        },*/
/*        {
          title: "河南省发展和改革委员会办公室\n" +
              "\n" +
              "关于组织申报2023年（第27批）省企业技术中心及开展\n" +
              "\n" +
              "省企业技术中心评价工作的通知",
          content: "各省辖市发展改革委、济源示范区发改统计局、郑州航空港经济综合实验区经济发展局、各县（市）发展改革委：\n" +
              "\n" +
              "为培育壮大战略性新兴产业，围绕产业链部署创新链，提升我省产业创新能力，今年省企业技术中心重点支持符合战略性新兴产业领域及我省28条产业链的企业。根据《河南省企业技术中心认定管理办法》（豫发改高技〔2018〕939号），现将省企业技术中心申报及评价工作有关事项通知如下:",
          publishTime: "2023年07月31日",
          source: "创新和高技术发展处",
          url: "https://fgw.henan.gov.cn/2023/07-31/2787789.html?jump_from=1_05_37_01"
        },*/
/*        {
          title: "工业和信息化部办公厅关于组织开展第六批国家级工业设计中心认定和第一批、第二批、第四批复核工作的通知",
          content: "各省、自治区、直辖市及计划单列市、新疆生产建设兵团工业和信息化主管部门：\n" +
              "\n" +
              "\n" +
              "\n" +
              "根据《国家级工业设计中心认定管理办法》（工信部政法〔2023〕93号，以下简称《管理办法》），我部决定组织开展第六批国家级工业设计中心认定工作，同时对第一批、第二批、第四批认定的国家级工业设计中心进行复核。有关事项通知如下：",
          publishTime: "2023-07-26",
          source: "工业和信息化部办公厅",
          url: "https://www.miit.gov.cn/zwgk/zcwj/wjfb/tz/art/2023/art_ee7c51f4e06649358144d8a4f62efc4b.html"
        },*/
/*        {
          title: "河南省工业和信息化厅办公室\n" +
              "关于开展2023年河南省创新型中小企业评价工作的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门：\n" +
              "\n" +
              "　　根据《河南省优质中小企业梯度培育管理实施细则（暂行）》（豫工信企业〔2023〕45号，以下简称《实施细则》）要求，为推进我省优质中小企业梯度培育工作，决定开展2023年河南省创新型中小企业评价工作。现将有关事项通知如下：",
          publishTime: "2023-07-24 18:00",
          source: "厅中小企业局",
          url: "https://gxt.henan.gov.cn/2023/07-24/2783660.html"
        },*/
/*        {
          title: "关于印发《国家级工业设计中心认定管理办法》的通知",
          content: "各省、自治区、直辖市及计划单列市、新疆生产建设兵团工业和信息化主管部门：\n" +
              "\n" +
              "\n" +
              "\n" +
              "　　现将《国家级工业设计中心认定管理办法》印发给你们，请结合实际认真贯彻落实。",
          publishTime: "2023-07-11 18:19:04",
          source: "工业和信息化部",
          url: "https://wap.miit.gov.cn/jgsj/zfs/gzdt/art/2023/art_3a9c67bc3b384804a4be43f04e496050.html"
        },*/
/*        {
          title: "关于河南省第五批专精特新“小巨人”企业和第二批专精特新“小巨人”复核通过企业名单的公示",
          content: "为深入贯彻习近平总书记关于“培育一批‘专精特新’中小企业”的重要指示精神，工业和信息化部开展了第五批专精特新“小巨人”企业培育和第二批专精特新“小巨人”企业复核工作，已完成相关审核，现将我省通过审核的企业名单予以公示（见附件1、2）。",
          publishTime: "2023-07-11 18:19:04",
          source: "厅中小企业局",
          url: "https://gxt.henan.gov.cn/2023/07-14/2778593.html"
        },*/
/*        {
          title: "关于2023年度郑州市工程系列中级职称评审工作有关问题的通知",
          content: "各开发区、区县（市）人力资源和社会保障局，市直有关单位：\n" +
              "\n" +
              "为做好2023年度全市工程系列中级职称评审工作，根据河南省职称制度改革相关文件精神，结合我市职称工作实际，现就有关事宜通知如下：",
          publishTime: "2023-07-11 18:19:04",
          source: "郑州市人力资源和社会保障局",
          url: "https://zzrs.zhengzhou.gov.cn/zczc/7628316.jhtml"
        },*/
/*        {
          title: "河南省工业和信息化厅办公室\n" +
              "关于开展2023年服务型制造标杆遴选工作的通知",
          content: "各开发区、区县（市）人力资源和社会保障局，市直有关单位：\n" +
              "\n" +
              "各省辖市、济源示范区、航空港区工业和信息化主管部门：\n" +
              "\n" +
              "　　为落实《中共河南省委办公厅 河南省人民政府办公厅关于印发〈河南省推动制造业高质量发展实施方案〉的通知》（豫办〔2020〕7号）、《实施数字化转型战略工作方案》（豫办〔2021〕41号）等文件精神，发挥标杆企业示范和带动作用，复制推广先进经验和成功模式，促进制造业高质量发展，经研究，省工业和信息化厅决定开展2023年河南省服务型制造标杆遴选工作。现将有关事项通知如下：",
          publishTime: "2023-07-11 16:22",
          source: "厅数字化与未来产业处",
          url: "https://gxt.henan.gov.cn/2023/07-11/2776559.html"
        },*/
/*        {
          title: "河南省工业和信息化厅办公室关于开展2023年智能制造标杆企业遴选工作的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门：\n" +
              "\n" +
              "　　为落实《中共河南省委办公厅 河南省人民政府办公厅关于印发〈河南省推动制造业高质量发展实施方案〉的通知》（豫办〔2020〕7号）、《实施数字化转型战略工作方案》（豫办〔2021〕41号）等文件精神，发挥标杆企业示范和带动作用，复制推广先进经验和成功模式，促进制造业高质量发展，经研究，省工业和信息化厅决定开展2023年河南省智能制造标杆企业遴选工作。现将有关事项通知如下：",
          publishTime: "2023-07-11 16:14",
          source: "厅数字化与未来产业处",
          url: "https://gxt.henan.gov.cn/2023/07-11/2776548.html"
        },*/
/*        {
          title: "国家税务总局 财政部关于优化预缴申报享受研发费用加计扣除政策有关事项的公告",
          content: "为更好地支持企业创新发展，根据《中华人民共和国企业所得税法》及其实施条例等相关规定，现就优化预缴申报享受研发费用加计扣除政策有关事项公告如下：",
          publishTime: "2023年6月21日",
          source: "国家税务总局 财政部",
          url: "http://www.chinatax.gov.cn/chinatax/n810341/n810825/c101434/c5205530/content.html#10006-weixin-1-52626-6b3bffd01fdde4900130bc5a2751b6d1"
        },*/
/*        {
          title: "最高奖励500万元，郑州航空港拟发文支持“专精特新”中小企业发展",
          content: "为贯彻落实国家关于支持“专精特新”中小企业高质量发展的决策部署和《河南省工业和信息化厅关于印发<河南省优质中小企业梯度培育管理实施细则（暂行）>的通知》（豫工信企业〔2023〕45号）文件精神，规范我区优质中小企业梯度培育工作，促进“专精特新”中小企业高质量发展，现结合我区实际，特制定本方案。",
          publishTime: "2023-05-18 08:39:46",
          source: "郑州航空港‌经济综合实验区",
          url: "./#/detail?id=1"
        },*/
/*        {
          title: "开封市人民政府最新通知！",
          content: "为贯彻落实市委市政府关于支持企业高质量发展的决策部署，积极发挥财政职能作用，进一步激发企业创新活力和发展动力，提升企业竞争力，现制定以下政策。",
          publishTime: "2023-06-21 17:09",
          source: "开封市人民政府",
          url: "https://mp.weixin.qq.com/s/dAVaGDGDJkWg4XVuQuSuKw"
        },*/
/*        {
          title: "郑州市工业和信息化局关于组织开展2022年度郑州市“专精特新”中小企业申报工作的通知",
          content: "各开发区、区县（市）科技主管部门，各有关单位：为深入贯彻落实“创新驱动、科教兴市、人才强市”战略，推动我市创新平台建设，依据《郑州市工程技术研究中心和重点实验室建设与管理办法》（郑科规〔2021〕4号），市科技局决定开展2023年度郑州市工程技术研究中心和重点实验室申报工作。现将有关事宜通知如下。",
          publishTime: "2023-05-18 08:39:46",
          source: "河南省科学技术局资源处"
        },*/
/*        {
          title: "豫工信办企业〔2022〕117号河南省工业和信息化厅办公室关于开展第四批专精特新“小巨人”企业推荐和第一批专精特新“小巨人”企业复核拟推荐名单及部分专精特新“小巨人”企业简单更名的公示",
          content: "各开发区、区县（市）科技主管部门，各有关单位：为深入贯彻落实“创新驱动、科教兴市、人才强市”战略，推动我市创新平台建设，依据《郑州市工程技术研究中心和重点实验室建设与管理办法》（郑科规〔2021〕4号），市科技局决定开展2023年度郑州市工程技术研究中心和重点实验室申报工作。现将有关事宜通知如下。",
          publishTime: "2023-05-18 08:39:46",
          source: "河南省科学技术局资源处"
        },*/
/*        {
          title: "关于做好工业企业2022年第四季度增产增效财政奖励资金申报工作的通知",
          content: "各开发区、区县（市）科技主管部门，各有关单位：为深入贯彻落实“创新驱动、科教兴市、人才强市”战略，推动我市创新平台建设，依据《郑州市工程技术研究中心和重点实验室建设与管理办法》（郑科规〔2021〕4号），市科技局决定开展2023年度郑州市工程技术研究中心和重点实验室申报工作。现将有关事宜通知如下。",
          publishTime: "2023-05-18 08:39:46",
          source: "河南省科学技术局资源处"
        },*/
        // {title:"豫工信联企业〔2022〕113号河南省工业和信息化厅河南省财政厅关于开展财政支持中小企业数字化转型试点工作的通知",content:"各开发区、区县（市）科技主管部门，各有关单位：为深入贯彻落实“创新驱动、科教兴市、人才强市”战略，推动我市创新平台建设，依据《郑州市工程技术研究中心和重点实验室建设与管理办法》（郑科规〔2021〕4号），市科技局决定开展2023年度郑州市工程技术研究中心和重点实验室申报工作。现将有关事宜通知如下。",publishTime:"2023-05-18 08:39:46",source:"河南省科学技术局资源处"}
      ],
      serviceProcessLeft: [
        {
          imageName: require("../assets/image/version1/home-third-content-1.png"),
          title: "确认需求",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-2.png"),
          title: "现场调研",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-3.png"),
          title: "项目规划",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-4.png"),
          title: "签订合同",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-5.png"),
          title: "项目会诊",
          keyword: "需求梳理 签订合同 紧急处理"
        }
      ],
      serviceProcessRight: [
        {
          imageName: require("../assets/image/version1/home-third-content-10.png"),
          title: "项目验收",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-9.png"),
          title: "跟踪服务",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-8.png"),
          title: "评审指导",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-7.png"),
          title: "申报项目",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-6.png"),
          title: "组织材料",
          keyword: "需求梳理 签订合同 紧急处理"
        },
      ],
      startUpPeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ],
      growthPeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ],
      maturePeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ]
    }
  },
  components: {
    NavHeader,
    NavFooter
  },
  created() {
  },
  mounted() {
  },
  methods: {
    homeTableHeaderRowClassName() {
      return "home-table-header-row"
    },
    homeTableHeaderCellClassName() {
      return "home-table-header-cell"
    },
    openLeaveContact() {
      this.$prompt('', '了解一下惠岑，开启业务新发展', {
        confirmButtonText: '免费咨询',
        showCancelButton: false,
        showClose: false,
        customClass: 'leave-contact-box',
        confirmButtonClass: 'leave-contact-box-button',
        inputPlaceholder: '请输入您的手机号',
        inputPattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
        inputErrorMessage: '手机号格式不正确'
      }).then(({value}) => {
        this.$message({
          type: 'success',
          message: '稍后我们将联系您: ' + value
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '请留下手机号，方便我们联系您'
        });
      });
    },
  }
}
</script>

<style scoped lang="scss">
.home {
  padding: 0px;

  .home-top {
    background-image: url("../assets/image/version1/home-top.png");
    background-position: center center;
    background-size: cover;
    height: vwf(590);
    text-align: center;

    img {
      width: vwf(30);
      height: vwf(30);
      vertical-align: middle;
    }

    span {
      font-size: vwf(30);
      font-weight: 500;
      color: #333333;
      margin-left: vwf(7);
    }

    .home-top-keyword {
      display: flex;
      align-items: center;
      margin: vwf(0) vwf(20);
    }

    .home-top-title {
      font-size: vwf(60);
      font-weight: 500;
      color: #333333;
      display: flex;
      padding-left: vwf(20);
    }

    .home-top-slogan {
      font-size: vwf(48);
      font-weight: 500;
      color: #333333;
      display: flex;
      padding-left: vwf(20);
    }

    .home-top-button {
      display: flex;
      padding-left: vwf(20);

      .el-button {
        width: vwf(240);
        height: vwf(80);
        background: #006DB8;
        border: 0px solid #DCDFE6;
        color: #FFFFFF;
        font-weight: 500;
        padding: vwf(18) vwf(60);
        font-size: vwf(30);
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
        border-radius: vwf(40);
      }
    }

    .home-top-title-row {
      margin-top: vwf(62);
    }

    .home-top-keyword-row {
      margin-top: vwf(60);
    }

    .home-top-button-row {
      margin-top: vwf(103);
    }
  }

  .home-second {
    height: vwf(937);

    .home-second-bg {
      background-image: url("../assets/image/version1/home-second-bg.png");
      background-position: center center;
      background-size: cover;
      height: vwf(360);
      text-align: center;
    }

    .home-second-title {
      position: absolute;
      top: vwf(100);
      width: 100%;
    }

    .home-second-content {
      position: absolute;
      top: vwf(180);
      width: 100%;

      .el-collapse {
        background: #FFFFFF;
        box-shadow: vwf(0) vwf(10) vwf(20) vwf(1) rgba(0, 0, 0, 0.16);
        border-radius: vwf(20) vwf(20) vwf(20) vwf(20);
        border: vwf(0);
        padding: vwf(30) vwf(38) vwf(30) vwf(38);
      }

      /deep/ .el-collapse-item__header {
        font-size: vwf(24);
        color: #333333;
        border-bottom: 0px;
        height: vwf(61);
        line-height: vwf(61);
      }

      .el-collapse-item-title {
        width: vwf(1234);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      /deep/ .el-collapse-item__content {
        p {
          padding-bottom: vwf(20);
          font-size: vwf(18);
          color: #666666;
          line-height: vwf(28);
        }

        span {
          padding-bottom: vwf(17);
          font-size: vwf(16);
          color: #999999;
          line-height: vwf(28);
        }
      }

      /deep/ .el-collapse-item__wrap {
        border-bottom: 0px;
      }
    }

    .home-second-content-button {
      display: flex;
      align-items: center;
      margin-top: vwf(50);

      .el-button {
        width: vwf(120);
        height: vwf(40);
        margin: 0 auto;
        background: #006DB8;
        border: 0px solid #DCDFE6;
        color: #FFFFFF;
        font-weight: 500;
        padding: vwf(8) vwf(28);
        font-size: vwf(16);
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
        border-radius: vwf(20);
      }
    }
  }

  .home-third {
    height: vwf(1000);
    background: #F7FAFD;

    .home-third-title {
      margin-top: vwf(100);
    }

    .home-third-content {
      margin-top: vwf(70);

      .home-third-content-row {
        width: 90%;
        height: vwf(116);
      }

      .home-third-content-row:hover {
        background: #006DB8;
        box-shadow: vwf(0) vwf(3) vwf(6) vwf(1) rgba(10, 65, 255, 0.16);
        border-radius: vwf(6) vwf(6) vwf(6) vwf(6);

        .home-third-content-title {
          color: #FFFFFF;
        }

        .home-third-content-keyword {
          color: #A1B6FF;
        }

        .home-third-content-no {
          filter: brightness(100);
        }

        .home-third-content-arrow {
          width: vwf(32);
          height: vwf(32);
          content: url("../assets/image/version1/home-third-content-arrow-s.png");
        }
      }

      .home-third-content-row-right {
        width: 90%;
        height: vwf(116);
        margin-left: 10%;
      }

      .home-third-content-row-right:hover {
        background: #006DB8;
        box-shadow: vwf(0) vwf(3) vwf(6) vwf(1) rgba(10, 65, 255, 0.16);
        border-radius: vwf(6) vwf(6) vwf(6) vwf(6);

        .home-third-content-title {
          color: #FFFFFF;
        }

        .home-third-content-keyword {
          color: #A1B6FF;
        }

        .home-third-content-no {
          filter: brightness(100);
        }

        .home-third-content-arrow-right {
          width: vwf(32);
          height: vwf(32);
          content: url("../assets/image/version1/home-third-content-arrow-s.png");
        }
      }

      .home-third-content-no {
        width: vwf(54);
        height: vwf(32);
      }

      .home-third-content-title {
        font-size: vwf(20);
        font-weight: blod;
        color: #333333;
      }

      .home-third-content-keyword {
        font-size: vwf(16);
        color: #BABECD;
      }

      .home-third-content-arrow {
        width: vwf(18);
        height: vwf(10);
        margin-left: vwf(40);
      }

      .home-third-content-arrow-right {
        width: vwf(18);
        height: vwf(10);
        margin-right: vwf(40);
        transform: rotate(180deg);
      }

      .home-third-content-m {
        width: vwf(468);
        height: vwf(580);
      }

    }
  }

  .home-fourth {
    .home-fourth-title {
      margin-top: vwf(100);
    }

    .home-fourth-content {
      margin-top: vwf(50);
    }
  }

  .home-fifth {
    .home-fifth-title {
      margin-top: vwf(100);
    }

    .home-fifth-content {
      margin-top: vwf(50);
    }
  }

  .home-sixth {
    margin-bottom: vwf(100);

    .home-sixth-title {
      margin-top: vwf(100);
    }

    .home-sixth-content {
      margin-top: vwf(50);
    }
  }

  .home-button-icon {
    width: vwf(12);
    height: vwf(12);
    vertical-align: middle;
    margin-left: vwf(6);
  }

  .home-look-all-button {
    .el-button {
      width: vwf(120);
      height: vwf(40);
      margin: 0 auto;
      background: #006DB8;
      border: 0px solid #DCDFE6;
      color: #FFFFFF;
      font-weight: 500;
      padding: vwf(8) vwf(19);
      font-size: vwf(16);
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
      border-radius: vwf(4);
    }
  }

  /deep/ .home-table-header-row {
    background-image: url(../assets/image/version1/home-table-header-bg.png);
    background-position: center center;
    background-size: cover;
  }

  /deep/ .home-table-header-cell {
    background-color: transparent;
  }


  .home-little-title {
    font-size: vwf(30);
    font-weight: bold;
    color: #333333;
  }

  .home-little-icon {
    width: vwf(24);
    height: vwf(24);
    vertical-align: middle;
  }

  // 覆盖elementUI
  .el-table {
    font-size: vwf(16);
    color: #333333;

    /deep/ .cell {
      line-height: 1.2 !important;
      white-space: pre-wrap;
    }

    /deep/ tbody tr:hover > td {
      background-color: #FFFFFF;
    }
  }

  /deep/ .el-table thead {
    color: #333333;
    font-weight: 400;
    font-size: vwf(20);
  }

  /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #F7F7F7;
  }

  /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }

  // 不起作用
  // /deep/ .leave-contact-box {
  //   width: vwf(420);
  //   padding-bottom: vwf(10);
  //   border-radius: vwf(4);
  //   border: vwf(1) solid #EBEEF5;
  //   font-size: vwf(18);
  //   box-shadow: 0 vwf(2) vwf(12) 0 rgba(0,0,0,.1);
  // }

}
</style>
